import React from "react"
import PartnerImg1 from "../../assets/images/partner-img/partner-images/partner1.png"
import PartnerHoverImg1 from "../../assets/images/partner-img/partner-images/hover1.png"
import PartnerImg2 from "../../assets/images/partner-img/partner-images/partner2.png"
import PartnerHoverImg2 from "../../assets/images/partner-img/partner-images/hover2.png"
import PartnerImg3 from "../../assets/images/partner-img/partner-images/partner3.png"
import PartnerHoverImg3 from "../../assets/images/partner-img/partner-images/hover3.png"
import PartnerImg4 from "../../assets/images/partner-img/partner-images/partner4.png"
import PartnerHoverImg4 from "../../assets/images/partner-img/partner-images/hover4.png"
import PartnerImg5 from "../../assets/images/partner-img/partner-images/partner5.png"
import PartnerHoverImg5 from "../../assets/images/partner-img/partner-images/hover5.png"
import PartnerImg7 from "../../assets/images/partner-img/partner-images/partner7.png"
import PartnerHoverImg7 from "../../assets/images/partner-img/partner-images/hover7.png"
import PartnerImg8 from "../../assets/images/partner-img/partner-images/partner8.png"
import PartnerHoverImg8 from "../../assets/images/partner-img/partner-images/hover8.png"
import PartnerImg9 from "../../assets/images/partner-img/partner-images/partner9.png"
import PartnerHoverImg9 from "../../assets/images/partner-img/partner-images/hover9.png"
import PartnerImg10 from "../../assets/images/partner-img/partner-images/partner_10.png"
import PartnerHoverImg10 from "../../assets/images/partner-img/partner-images/hover_10.png"
import PartnerImg11 from "../../assets/images/partner-img/partner-images/partner11.png"
import PartnerHoverImg11 from "../../assets/images/partner-img/partner-images/hover11.png"
import PartnerImg12 from "../../assets/images/partner-img/partner-images/partner12.png"
import PartnerHoverImg12 from "../../assets/images/partner-img/partner-images/hover12.png"
import PartnerImg13 from "../../assets/images/partner-img/partner-images/partner13.png"
import PartnerHoverImg13 from "../../assets/images/partner-img/partner-images/hover13.png"
import PartnerImg14 from "../../assets/images/partner-img/partner-images/partner14.png"
import PartnerHoverImg14 from "../../assets/images/partner-img/partner-images/hover14.png"
import PartnerImg15 from "../../assets/images/partner-img/partner-images/partner15.png"
import PartnerHoverImg15 from "../../assets/images/partner-img/partner-images/hover15.png"
// import swiper

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/free-mode"
import { Autoplay, FreeMode } from "swiper"

const Partner = () => {
  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  const carouselItems = [
    {
      id: 1,
      title: "Salesforce logo",
      image: PartnerImg2,
      hoverImage: PartnerHoverImg2,
    },
    {
      id: 2,
      title: "Redhat logo",
      image: PartnerImg9,
      hoverImage: PartnerHoverImg9,
    },
    {
      id: 3,
      title: "Snowflakes logo",
      image: PartnerImg3,
      hoverImage: PartnerHoverImg3,
    },
    {
      id: 4,
      title: "GCP logo",
      image: PartnerImg12,
      hoverImage: PartnerHoverImg12,
    },
    {
      id: 5,
      title: "Gresham logo",
      image: PartnerImg5,
      hoverImage: PartnerHoverImg5,
    },
 
    {
      id: 6,
      title: "Grafana logo",
      image: PartnerImg7,
      hoverImage: PartnerHoverImg7,
    },
    {
      id: 7,
      title: "Finastra logo",
      image: PartnerImg11,
      hoverImage: PartnerHoverImg11,
    },
 
    {
      id: 8,
      title: "AWS logo",
      image: PartnerImg10,
      hoverImage: PartnerHoverImg10,
    },
      {
      id: 9,
      title: "Azure logo",
      image: PartnerImg13,
      hoverImage: PartnerHoverImg13,
    },
    {
      id: 11,
      title: "Databricks logo",
      image: PartnerImg4,
      hoverImage: PartnerHoverImg4,
    },
    {
      id: 12,
      title: "Hashocorp logo",
      image: PartnerImg1,
      hoverImage: PartnerHoverImg1,
    },
    {
      id: 13,
      title: "Harness logo",
      image: PartnerImg14,
      hoverImage: PartnerHoverImg14,
    },
    {
      id: 14,
      title: "Datadog logo",
      image: PartnerImg8,
      hoverImage: PartnerHoverImg8,
    },
    {
      id: 15,
      title: "fenergo",
      image: PartnerImg15,
      hoverImage: PartnerHoverImg15,
    },
  ]

  return (
    <div className="repair-partner-area bg-f9fafb ">
      <div className="container-fulid">
        {display && (
          <Swiper
            spaceBetween={5}
            slidesPerView={1}
            breakpoints={{
              576: {
                width: 576,
                slidesPerView: 3,
              },
              783: {
                width: 783,
                slidesPerView: 3,
              },
              1024: {
                width: 1024,
                slidesPerView: 4,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            loopFillGroupWithBlank={true}
            modules={[Autoplay, FreeMode]}
            className="partner-slides"
          >
            {carouselItems.map(item => (
              <SwiperSlide key={item.id} className="single-ml-partner">
                <div>
                  <img src={item.image} alt={item.title} />
                  <img src={item.hoverImage} alt={item.title} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  )
}

export default Partner
